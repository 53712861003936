<template>
  <div>
    <Disclosure />
  </div>
</template>

<script>
import Disclosure from '@/components/Disclosure/Disclosure';

export default {
  name: 'DisclosurePage',
  components: {
    Disclosure,
  },
};
</script>
