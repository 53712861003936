<template>
  <div class="container-sm p-1">
    <div class="text-center">
      <h1 data-test="disclosure-heading" class="mt-1-half mb-2">
        {{ $t('ConsentDisclosureTitle') }}
      </h1>
    </div>
    <p class="mb-1-half">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique quas
      nam reprehenderit amet, excepturi ipsa! Asperiores est ipsum neque quae,
      at perferendis qui quasi quod? Alias dolor sit est vel.
    </p>
    <p class="mb-1-half">
      Deserunt illo nam ipsum blanditiis ipsa ex iste hic corporis molestiae aut
      ipsam itaque necessitatibus, rerum eaque provident earum debitis
      voluptatem facilis saepe laudantium beatae vel nisi perspiciatis. A, vel.
    </p>
    <p class="mb-1-half">
      Nobis quod voluptatem exercitationem ut illum veritatis molestiae
      praesentium? Cupiditate quisquam ad in qui dolore accusamus sunt.
      Aspernatur animi exercitationem explicabo temporibus? Reprehenderit ipsum
      nam in laudantium ducimus magni est!
    </p>
    <p class="mb-1-half">
      Consequatur accusantium esse aspernatur voluptate, nulla debitis, at quis
      vel dolor obcaecati neque molestiae magni excepturi fugit alias quo quos,
      consequuntur blanditiis? Asperiores quibusdam exercitationem mollitia
      fugiat eveniet saepe. Similique?
    </p>
    <p class="mb-1-half">
      A autem explicabo nulla voluptates sunt quaerat nihil esse aliquid, dolore
      qui cum ab consequuntur optio omnis delectus ea officia rem. Dolores ullam
      cum vero rem sit saepe impedit fugiat.
    </p>
    <div class="consent-buttons mt-2-half">
      <a
        href="#"
        class="button extra-large mb-1 sm:mr-half"
        data-test="disclosure-decline-button"
        @click.prevent="declineDiscloure()"
        >{{ $t('DeclineButtonText') }}</a
      >
      <router-link
        to="emergency"
        class="button extra-large mb-1"
        data-test="disclosure-agree-button"
        >{{ $t('AgreeButtonText') }}</router-link
      >
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  name: 'Disclosure',

  methods: {
    declineDiscloure() {
      this.$store.dispatch('setActiveModal', 'declinedDisclosure');
      this.$router.push('/');
    },
  },
});
</script>
